// material-ui
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import Chip from 'ui-component/extended/Chip';
import ModalRecord from './ModalRecord';
import ModalTrash from 'modalTrash/ModalTrash';
import EditIcon from '@mui/icons-material/EditTwoTone';
import IconTrash from '@mui/icons-material/DeleteOutlineTwoTone';
import axios from 'utils/axios';
import {
    Button,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableSortLabel,
    TableRow
} from '@mui/material';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import ModalStatusChange from 'layout/ModalStatusChange/ModalStatusChange';
import * as ROLES from '../../roles';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import user from 'store/slices/user';
import { RotateLeftSharp } from '@mui/icons-material';
import { parseDate } from '../../utils/helpers';

// ==============================|| INSTRUMENTS ||============================== //

const Holidays = () => {
    const [trashId, setTrashId] = useState('');
    const [trashModal, setTrashModal] = useState(false);
    const [statusId, setStatusId] = useState('');
    const [statusModal, setStatusModal] = useState(false);
    const [record, setRecord] = useState(null);
    const [holidays, setHolidays] = useState([]);
    const [modal, setModal] = useState(false);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        limit: 10,
        offset: 0,
        fields: ['*'],
        meta: '*',
        sort: '-id',
        search: ''
    });
    const dispatch = useDispatch();
    const getHolidays = async () => {
        const response = await axios.get('/items/holidays', { params });
        const data = response.data.data;
        setHolidays(data);
        setTotal(response.data.meta.total_count);
    };
    const onModalClick = () => {
        setRecord(null);
        setModal(!modal);
    };
    const onStatusClick = (id) => {
        setStatusModal(!statusModal);
        setStatusId(id);
    };
    const onEditClick = (editRecord) => {
        setRecord(editRecord);
        setModal(!modal);
    };
    const onSearch = (e) => {
        setParams((prev) => ({
            ...prev,
            search: e.target.value
        }));
    };
    const setNewHoliday = async (holiday) => {
        await axios.post('/items/holidays', holiday);
        getHolidays();
        onModalClick();
        dispatch(
            openSnackbar({
                open: true,
                message: 'Chiusura creata con successo',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };
    const onSort = (name) => {
        if (name === params.sort) {
            if (name.charAt(0) === '-') {
                name = name.substring(0);
            } else {
                name = `-${name}`;
            }
        }
        setParams((prev) => ({
            ...prev,
            sort: name
        }));
    };
    useEffect(() => {
        getHolidays();
    }, [params]);
    const onPageChange = (e, page) => {
        setParams((prev) => ({
            ...prev,
            offset: page * params.limit
        }));
    };
    const onUpdate = async (updateRecord) => {
        await axios.patch(`/items/holidays/${updateRecord.id}`, updateRecord);
        getHolidays();
        setModal(!modal);
    };
    const onDelete = async (id) => {
        await axios.delete(`/items/holidays/${id}`);
        getHolidays();
        dispatch(
            openSnackbar({
                open: true,
                message: 'Chiusura cancellata con successo',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };
    const onTrashClick = (id) => {
        setTrashId(id);
        setTrashModal(!trashModal);
    };
    const onRowsPerPage = (e) => {
        setParams((prev) => ({
            ...prev,
            limit: e.target.value
        }));
    };
    return (
        <MainCard
            title="Gestione Sedi"
            secondary={
                <Button onClick={onModalClick} variant="contained" color="secondary">
                    <AddCircleIcon fontSize="small" sx={{ mr: 0.75 }} />
                    Aggiungi chiusura
                </Button>
            }
        >
            <ModalStatusChange open={statusModal} id={statusId} sede onStatusClick={onStatusClick} getHolidays={getHolidays} />
            <ModalTrash
                title="Cancellare la chiusura?"
                text="Cancellandola, eliminerai anche tutte le Aule associate."
                open={trashModal}
                trashId={trashId}
                onDelete={onDelete}
                onTrashClick={onTrashClick}
            />
            <ModalRecord open={modal} onUpdate={onUpdate} record={record} setNewRecord={setNewHoliday} onModalClick={onModalClick} />
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                        </InputAdornment>
                    )
                }}
                onChange={onSearch}
                placeholder="Cerca chiusura..."
                value={params.search}
                size="small"
            />
            <Divider sx={{ mt: 3 }} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    onClick={() => {
                                        onSort('description');
                                    }}
                                    direction={params.sort.charAt(0) === '-' ? 'desc' : 'asc'}
                                    active={params.sort.includes('description')}
                                >
                                    Descrizione
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={() => {
                                        onSort('start_date');
                                    }}
                                    direction={params.sort.charAt(0) === '-' ? 'desc' : 'asc'}
                                    active={params.sort.includes('start_date')}
                                >
                                    Dal
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    onClick={() => {
                                        onSort('end_date');
                                    }}
                                    direction={params.sort.charAt(0) === '-' ? 'desc' : 'asc'}
                                    active={params.sort.includes('end_date')}
                                >
                                    Al
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">Azioni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {holidays.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{parseDate(item.start_date, 'dd/MM/yyyy')}</TableCell>
                                <TableCell>{parseDate(item.end_date, 'dd/MM/yyyy')}</TableCell>
                                <TableCell align="right">
                                    <IconButton color="secondary" onClick={() => onEditClick(item)}>
                                        <EditIcon sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                    <IconButton color="error" onClick={() => onTrashClick(item.id)}>
                                        <IconTrash sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={total}
                    rowsPerPage={params.limit}
                    page={Math.floor(params.offset / params.limit)}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPage}
                    labelRowsPerPage="Righe per pagina"
                />
            </TableContainer>
        </MainCard>
    );
};

export default Holidays;
