// eslint-disable-next-line import/prefer-default-export
import { parseISO, format, differenceInDays, differenceInWeeks, differenceInMonths, addDays, addMonths } from 'date-fns';

export const lastChars = (text, length = 2) => text.substring(text.length - length);

export const getToday = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
};

export const parseDate = (date, returns = null) => {
    const dateObject = new Date(date);
    date = new Date(Date.parse(date));
    if (!returns) return date;
    switch (returns) {
        case 'h':
            return date.getHours();
        case 'hh':
            return lastChars(`0${date.getHours()}`);
        case 'm':
            return date.getMinutes();
        case 'mm':
            return lastChars(`00${date.getMinutes()}`);
        case 'dd/MM/yyyy':
            return dateObject.toLocaleDateString('it-it');
        case 'dd/MM/yyyy hh:mm':
            return `${dateObject.toLocaleDateString('it-it')} ${dateObject.toLocaleTimeString('it-IT')}`;
        case 'hh:mm':
            return `${lastChars(`0${date.getHours()}`)}:${lastChars(`0${date.getMinutes()}`)}`;
        default:
            return date;
    }
};

export const leadingZeros = (num, size = 2) => {
    num = num.toString();
    while (num.length < size) num = `0${num}`;
    return num;
};

export const parseStringDate = (dateString) => {
    const ISODate = new Date(dateString).toISOString();
    return parseISO(ISODate);
};

export const getRoomReservationRepeats = (repeatType, dateStart, dateEnd, repeatEnd = null, length = null) => {
    const inserts = [];
    let dateStartOriginal = parseStringDate(dateStart);
    let dateEndOriginal = parseStringDate(dateEnd);

    if (repeatType === 'day') {
        const repeatStart = new Date(dateStart.substring(0, 10));
        let diffDays;
        if (repeatEnd) {
            repeatEnd = new Date(repeatEnd.substring(0, 10));
            diffDays = differenceInDays(repeatEnd, repeatStart);
        } else {
            diffDays = length;
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < diffDays; i++) {
            dateStartOriginal = addDays(dateStartOriginal, 1);
            dateEndOriginal = addDays(dateEndOriginal, 1);

            inserts.push({
                date_start: format(dateStartOriginal, 'yyyy-MM-dd HH:mm:ss'),
                date_end: format(dateEndOriginal, 'yyyy-MM-dd HH:mm:ss')
            });
        }
    } else if (repeatType === 'week') {
        let weeks;
        if (repeatEnd) {
            repeatEnd = new Date(repeatEnd.substring(0, 10));
            const repeatStart = new Date(dateStart.substring(0, 10));
            weeks = differenceInWeeks(repeatEnd, repeatStart);
        } else {
            weeks = length;
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < weeks; i++) {
            dateStartOriginal = addDays(dateStartOriginal, 7);
            dateEndOriginal = addDays(dateEndOriginal, 7);
            inserts.push({
                date_start: format(dateStartOriginal, 'yyyy-MM-dd HH:mm:ss'),
                date_end: format(dateEndOriginal, 'yyyy-MM-dd HH:mm:ss')
            });
        }
    } else if (repeatType === 'month') {
        let diffMonths;
        if (repeatEnd) {
            repeatEnd = new Date(repeatEnd.substring(0, 10));
            const repeatStart = new Date(dateStart.substring(0, 10));
            diffMonths = differenceInMonths(repeatEnd, repeatStart);
        } else {
            diffMonths = length;
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < diffMonths; i++) {
            dateStartOriginal = addMonths(dateStartOriginal, 1);
            dateEndOriginal = addMonths(dateEndOriginal, 1);
            inserts.push({
                date_start: format(dateStartOriginal, 'yyyy-MM-dd HH:mm:ss'),
                date_end: format(dateEndOriginal, 'yyyy-MM-dd HH:mm:ss')
            });
        }
    }

    return inserts;
};
