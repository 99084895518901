import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

const ModalTrash = ({ open, onTrashClick, onDelete, trashId, title, text }) => {
    const onCancell = () => {
        onDelete(trashId);
        onTrashClick();
    };
    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onTrashClick} aria-labelledby="form-dialog-title">
            {open && (
                <form>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{text}</DialogContentText>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button onClick={onTrashClick} color="secondary">
                            Annulla
                        </Button>
                        <Button variant="contained" onClick={onCancell}>
                            Cancella
                        </Button>
                    </DialogActions>
                </form>
            )}
            ;
        </Dialog>
    );
};
export default ModalTrash;
