import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Dialog,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
// assets
import { IconBell, IconSettings } from '@tabler/icons';
import ModalAdmin from 'layout/MainLayout/ModalAdmin/ModalAdmin';
import * as ROLES from '../../../../roles';
import { removeEvent, updateEvent } from '../../../../store/slices/classroomCalendar';
import { useDispatch } from 'store';
import AddEventForm from '../../../../views/instrument-reservations/AddEventForm';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [notifications, setNotifications] = useState([]);
    const [modalAdmin, setModalAdmin] = useState(false);
    const [instrumentConfirmation, setInstrumentConfirmation] = useState(null);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState({
        limit: 5,
        offset: 0,
        fields: [
            '*',
            'instrument_reservation.*',
            'instrument_reservation.profile.*',
            'instrument_reservation.user_created.*',
            'instrument_reservation.instrument.*',
            'instrument_reservation.instrument.category.*'
        ],
        meta: '*',
        sort: '-id',
        search: '',
        filter: ''
    });
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const onAdminModalClick = () => {
        setModalAdmin(!modalAdmin);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => setValue(event?.target.value);
    const loadNotifications = async () => {
        const res = await axios.get('/items/notifications', {
            params: {
                ...params,
                filter: {
                    ...params.filter,
                    recipient: user?.id
                }
            }
        });
        setNotifications(res.data.data);
        const count = await axios.get('/items/notifications', {
            params: {
                ...params,
                filter: {
                    ...params.filter,
                    recipient: user?.id,
                    read: false
                }
            }
        });
        setTotal(count.data.meta.filter_count);
    };
    const checkNewMessage = notifications.find((item) => item.read === false);
    const notificationId = notifications.map((item) => item.id);

    const onReadAllClick = async () => {
        // if (!checkNewMessage) {
        //     return;
        // }
        const res = await axios.get('/items/notifications', {
            params: {
                limit: -1,
                offset: 0,
                fields: ['id'],
                meta: '*',
                sort: '-id',
                search: '',
                filter: {
                    ...params.filter,
                    recipient: user?.id,
                    read: false
                }
            }
        });

        const ids = res.data.data.map((notification) => notification.id);
        if (ids.length) {
            await axios.patch('/items/notifications', { keys: ids, data: { read: true } });
        }
        loadNotifications();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            loadNotifications();
        }, 30000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        loadNotifications();
    }, [params]);

    const handleShowInstrumentReservation = (record) => {
        setInstrumentConfirmation(record);
    };

    const handleInstrumentSave = async (eventId, update) => {
        dispatch(updateEvent({ eventId, update }));
        setInstrumentConfirmation(null);
        setTimeout(() => {
            loadNotifications();
        }, 500);
    };

    const handleInstrumentCancel = async () => {
        setInstrumentConfirmation(null);
    };

    const handleInstrumentDelete = async (id) => {
        try {
            dispatch(removeEvent(id));
            setInstrumentConfirmation(null);
        } catch (err) {
            console.error(err);
        }
        setInstrumentConfirmation(null);
        setTimeout(() => {
            loadNotifications();
        }, 500);
    };

    return (
        <>
            <ModalAdmin open={modalAdmin} onClose={onAdminModalClick} />
            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={() => setInstrumentConfirmation(null)}
                open={instrumentConfirmation}
                sx={{ '& .MuiDialog-paper': { p: 0, minHeight: '400px' } }}
            >
                {instrumentConfirmation && (
                    <AddEventForm
                        onCancel={handleInstrumentCancel}
                        handleDelete={handleInstrumentDelete}
                        handleUpdate={handleInstrumentSave}
                        event={instrumentConfirmation}
                    />
                )}
            </Dialog>
            {user?.role === ROLES.ADMIN && (
                <Box
                    sx={{
                        ml: 10,

                        [theme.breakpoints.down('md')]: {
                            mr: 2
                        }
                    }}
                >
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={onAdminModalClick}
                        color="inherit"
                    >
                        <IconSettings stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Box>
            )}

            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <IconBell stroke={1.5} size="1.3rem" />
                </Avatar>
                {total > 0 && (
                    <Chip
                        size="small"
                        label={total}
                        sx={{
                            color: theme.palette.background.default,
                            bgcolor: theme.palette.warning.dark,
                            position: 'absolute',
                            top: '20px',
                            right: '132px'
                        }}
                    />
                )}
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">Tutte le notifiche</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            component={Link}
                                                            onClick={onReadAllClick}
                                                            to="#"
                                                            variant="subtitle2"
                                                            color="primary"
                                                        >
                                                            Segna come letto
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            {/* <Box sx={{ px: 2, pt: 0.25 }}>
                                                                <TextField
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={handleChange}
                                                                    SelectProps={{
                                                                        native: true
                                                                    }}
                                                                >
                                                                    {status.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Box> */}
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList
                                                        notifications={notifications}
                                                        reloadNotifications={loadNotifications}
                                                        handleConfirmInstrumentReservation={handleShowInstrumentReservation}
                                                    />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Link style={{ textDecoration: 'none' }} to="/notifications">
                                                <Button size="small" disableElevation>
                                                    Vedi tutti
                                                </Button>
                                            </Link>
                                        </CardActions>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
