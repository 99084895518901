import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Checkbox,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';
import * as ROLES from '../../roles';
// third-party
import _, { isNumber } from 'lodash';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import ReturnForm from './ReturnForm';
import CircularIndeterminate from './Spinner';
import itLocale from 'date-fns/locale/it';

// project imports
import { gridSpacing } from 'store/constant';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'utils/axios';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseDate } from '../../utils/helpers';
import { Alert } from '@mui/lab';

// constant

const getInitialValues = (event, range, profile, adc, ic) => {
    // eslint-disable-next-line camelcase
    const date_start = range ? new Date(range.start) : new Date();
    // eslint-disable-next-line camelcase
    const date_end = range ? new Date(range.end) : new Date();

    return {
        other: null,
        profile,
        date_pickup: range ? new Date(range.end) : new Date(),
        note: '',
        confirmed_by_admin: adc,
        confirmed_by_teacher: ic,
        // color: '#2196f3',
        // textColor: '',
        // allDay: false,
        date_start,
        date_end,
        ...event,
        instrument: event ? event.instrument.id : ''
    };
};

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

const AddEventFrom = ({
    event,
    events = [],
    range = null,
    profile = null,
    setSelectedEvent = () => null,
    onCancel = () => null,
    filteredEvents = [],
    updateEvents = () => {},
    getEvents = () => null
}) => {
    const isCreating = !event;
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [returnInst, setReturnInst] = useState([]);
    const [instruments, setInstruments] = useState();
    const [users, setUsers] = useState([]);
    const [returnModal, setReturnModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState(null);
    const [controls, setControls] = useState({
        max_strumenti: 999999999999,
        min_preavviso_strumento: 999999999999
    });
    const getCategories = async () => {
        const response = await axios.get('/items/instrument_categories');
        const data = response.data.data;
        setCategories(data);
    };
    const adc = user.role === ROLES.ADMIN;
    const ic = user.role === ROLES.INSEGNANTE;

    const getInstruments = async () => {
        const response = await axios.get(`/items/instruments`, {
            params: {
                filter: {
                    status: { _neq: 'private' },
                    ...(!category ? {} : { category: { _eq: category } })
                }
            }
        });
        const data = response.data.data;
        setInstruments(data);
    };

    const getReturnInstruments = async () => {
        const response = await axios.get('/items/instrument_returns');
        const data = response.data.data;
        setReturnInst(data.filter((item) => item.reservation === event?.id));
    };
    const now = +new Date();
    const pickUp = +new Date(event?.date_pickup);
    const AfterPickUpTime = now > pickUp;

    const checkIfOverlap = (id, dataInizio, dataFine, event) => {
        if (typeof id === 'object') {
            id = id.id;
        }
        let overlap = false;
        let filteredReservations = events.filter((item) => item?.classroom?.id === parseInt(id, 10));
        // se è update
        // devo rimuovere dagli eventi da controllare l'evento corrente
        if (event) {
            filteredReservations = filteredReservations.filter((item) => item.id !== parseInt(event.id, 10));
        }
        filteredReservations.forEach((evnt) => {
            const start = new Date(evnt.date_start);
            const end = new Date(evnt.date_end);
            overlap = dataInizio <= end && dataFine >= start;
        });
        return overlap;
    };

    const getUsers = async () => {
        const response = await axios.get('/items/profiles');
        const data = response.data.data;
        setUsers(data);
    };

    const getOptions = async () => {
        const response = await axios.get('/items/options');
        const data = response.data.data;
        await setOptions(data);

        let newControls = {};

        if (user.role === ROLES.INSEGNANTE) {
            newControls = {
                max_strumenti: data.max_strumenti_insegnante,
                min_preavviso_strumento: data.min_preavviso_strumento_insegnante
            };
        }
        if (user.role === ROLES.STUDENTE) {
            newControls = {
                max_strumenti: data.max_strumenti_studente,
                min_preavviso_strumento: data.min_preavviso_strumento_studente
            };
        }

        setControls((prevState) => ({ ...prevState, ...newControls }));
    };

    const onReturnButtonClick = () => {
        setReturnModal(!returnModal);
    };

    useEffect(() => {
        getInstruments();
    }, [category]);

    useEffect(() => {
        Promise.all([getOptions(), getUsers(), getCategories(), getReturnInstruments()]).then(() => {
            setLoading(false);
        });
        if (event) {
            setCategory(event.instrument.category.id);
        }
    }, []);

    const EventSchema = Yup.object().shape({
        // note: Yup.string().max(5000),
        instrument: Yup.mixed().required('Inserisci lo strumento'),

        date_end: Yup.string().required('Inserisci data di fini'),
        date_start: Yup.string().required('Inserisci data di inizio')
    });

    const setNewDate = async (data) => {
        // const startDate = new Date(data.date_start);
        // const pickupDate = new Date(data.date_pickup); // .setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

        const values = {
            ...data
            // date_start: new Date(+new Date(data.date_start).setHours(9, 0, 0, 0)),
            // date_end: new Date(+new Date(data.date_end) + 60 * 1000),
            // date_pickup: new Date(pickupDate)
        };

        try {
            await axios.post('/items/instrument_reservations', values);
            getCategories();
            updateEvents();
            setSelectedEvent(null);
        } catch (e) {
            openSnackbar({
                open: true,
                message: 'Si è verificato un errore server',
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            });
        }
    };

    const updateEvent = async (id, data) => {
        await axios.patch(`/items/instrument_reservations/${id}`, data);
        getCategories();
        updateEvents();
        setSelectedEvent(null);
    };

    const deleteEvent = async (id) => {
        await axios.delete(`/items/instrument_reservations/${id}`);
        updateEvents();
        getCategories();
        setSelectedEvent(null);
    };

    let chipSettings = {
        BGcolor: '',
        color: '',
        text: '',
        left: ''
    };
    if (!event?.confirmed_by_admin && !event?.confirmed_by_teacher) {
        chipSettings = {
            color: '#f79b23',
            BGcolor: '#f5f095',
            text: 'In attesa conferma docente',
            left: '24rem'
        };
    }
    if (!event?.confirmed_by_admin && event?.confirmed_by_teacher) {
        chipSettings = {
            color: '#f79b23',
            BGcolor: '#f5f095',
            text: 'In attesa conferma admin',
            left: '25rem'
        };
    }
    if (event?.confirmed_by_admin) {
        chipSettings = {
            color: '#bce6ce',
            BGcolor: '#0db555',
            text: 'Confermato',
            left: '30.5rem'
        };
    }

    if (event?.status === 'completed') {
        chipSettings = {
            color: '#bce6ce',
            BGcolor: '#0db555',
            text: 'Concluso',
            left: '30.5rem'
        };
    }

    const closeReservation = async ({ id }) => {
        await axios.patch(`/items/instrument_reservations/${id}`, {
            status: 'completed'
        });
        setSelectedEvent(null);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formik = useFormik({
        initialValues: getInitialValues(event, range, profile, adc, ic),
        validationSchema: EventSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            const nowPlusNotice = +new Date() + controls.min_preavviso_strumento * 60 * 60 * 1000;
            const startDate = new Date(values.date_start);
            const endDate = new Date(values.date_end);
            const oldPickupDate = new Date(values.date_pickup);
            const pickupDate = new Date(values.date_start);
            pickupDate.setHours(oldPickupDate.getHours());
            pickupDate.setMinutes(oldPickupDate.getMinutes());
            pickupDate.setSeconds(0);

            const yearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
            const dt = new Date();
            dt.setFullYear(dt.getFullYear() + 1);
            if (values.date_end - values.date_start >= yearInMilliseconds) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'La prenotazione è consentita per un massimo di 365 giorni',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (endDate <= startDate) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'La data di termine prenotazione deve essere successiva a quella di inizio',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (pickupDate < startDate) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `L'ora di ritiro deve essere uguale o successiva all'orario di inizio prenotazione`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            // if ((pickupDate).setHours(0, 0, 0, 0) < dateStart.setHours(0, 0, 0, 0)) {
            //     dispatch(
            //         openSnackbar({
            //             open: true,
            //             message: 'La data di ritiro deve essere successiva al giorno di inizio della prenotazione',
            //             variant: 'alert',
            //             alert: {
            //                 color: 'error'
            //             },
            //             close: false
            //         })
            //     );
            //     return;
            // }

            const pickupHour = parseDate(pickupDate, 'h');
            if (pickupHour < controls.min_ora_ritiro_strumento || pickupHour > controls.max_ora_ritiro_strumento) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Il ritiro è consentito dalle ${controls.min_ora_ritiro_strumento} alle ${controls.max_ora_ritiro_strumento}`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'La prenotazione può essere aggiunta solo dal Lunedì al Venerdì',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (endDate.getDay() === 0 || endDate.getDay() === 6) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Lo strumento può essere restituito solo dal Lunedì al Venerdì',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (user.role !== ROLES.ADMIN && +values.date_start < nowPlusNotice) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Il tempo di preavviso per la prenotazione non è sufficiente',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (checkIfOverlap(values.instrument, new Date(values.date_start), new Date(values.date_end), event)) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Lo strumento non è disponibile',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }
            if (filteredEvents.length >= controls.max_strumenti) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Hai raggiunto il limite massimo di prenotazioni consentito',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }
            if (!isCreating) {
                if (
                    (user.role === ROLES.INSEGNANTE && !event.confirmed_by_teacher && !values.confirmed_by_teacher) ||
                    (user.role === ROLES.ADMIN && event.confirmed_by_teacher && !event.confirmed_by_admin && !values.confirmed_by_admin)
                ) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Per confermare la prenotazione è necessario spuntare la relativa checkbox',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                    // eslint-disable-next-line consistent-return
                    return false;
                }

                if (user.role === ROLES.INSEGNANTE) {
                    updateEvent(event.id, { confirmed_by_teacher: values.confirmed_by_teacher });
                }
                if (user.role === ROLES.ADMIN) {
                    updateEvent(event.id, {
                        confirmed_by_admin: values.confirmed_by_admin,
                        instrument: values.instrument,
                        other: values.other,
                        profile: values.profile,
                        date_pickup: values.date_pickup,
                        date_start: values.date_start,
                        date_end: values.date_end,
                        note: values.note
                    });
                }
            } else {
                if (values.profile === '') {
                    values = {
                        ...values,
                        profile: user?.profiles?.[0]
                        // date_end: new Date(new Date(values.date_end) + 2 * 60 * 60 * 1000),
                    };
                }
                //
                // values = {
                //     ...values
                //     // date_start: new Date(values.date_start).setHours(9, 0, 0, 0),
                //     // date_end: new Date(new Date(values.date_end) + 2 * 60 * 60 * 1000)
                // };

                formik.setValues(values);
                setNewDate(values);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    if (loading) {
        return <CircularIndeterminate />;
    }

    return (
        <FormikProvider sx={{ width: '200rem' }} value={formik}>
            {user.role !== ROLES.ADMIN && (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                    {!returnModal && (
                        <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                            <DialogTitle>
                                Prenotazione Strumento{' '}
                                {event && (
                                    <Chip
                                        size="large"
                                        label={chipSettings.text}
                                        // chipcolor={item.status === 'active' ? 'success' : 'warning'}
                                        sx={{
                                            position: 'absolute',
                                            backgroundColor: chipSettings.BGcolor,
                                            color: chipSettings.color,
                                            left: chipSettings.left
                                        }}
                                    />
                                )}
                            </DialogTitle>
                            <Divider />
                            <DialogContent sx={{ p: 3 }}>
                                <Grid container spacing={gridSpacing}>
                                    {event && user.role === ROLES.INSEGNANTE && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Creato da"
                                                disabled
                                                value={`${event?.user_created?.first_name} ${event?.user_created?.last_name}`}
                                            />
                                        </Grid>
                                    )}

                                    {user.role === ROLES.INSEGNANTE ? (
                                        <Grid item xs={12}>
                                            {!event ? (
                                                <TextField
                                                    fullWidth
                                                    select
                                                    value={formik.values.profile}
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    label="Utente associato"
                                                    {...getFieldProps('profile')}
                                                    error={Boolean(touched.profile && errors.profile)}
                                                    helperText={touched.profile && errors.profile}
                                                >
                                                    <option>--</option>

                                                    {users
                                                        ?.filter((user) => user.type !== 'administrator' || user.role === ROLES.ADMIN)
                                                        .map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {`${item.first_name} ${item.last_name}`}
                                                            </option>
                                                        ))}
                                                </TextField>
                                            ) : (
                                                <TextField
                                                    fullWidth
                                                    label="Utente associato"
                                                    disabled
                                                    value={`${event?.profile?.first_name} ${event?.profile?.last_name}`}
                                                />
                                            )}
                                        </Grid>
                                    ) : null}

                                    {event ? (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Categoria strumento"
                                                disabled
                                                value={event?.instrument?.category?.name}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                error={!category && checkError}
                                                helperText={!category && checkError && 'Inserisci la categoria'}
                                                label="Categoria strumento"
                                                onChange={(e) => {
                                                    setCategory(e.target.value);
                                                }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                            >
                                                <option>--</option>

                                                {categories.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )}

                                    {event ? (
                                        <Grid item xs={12}>
                                            <TextField fullWidth label="Strumento" disabled value={event?.instrument?.name} />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                value={formik.values.instrument}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                label="Strumento"
                                                {...getFieldProps('instrument')}
                                                error={Boolean(touched.instrument && errors.instrument)}
                                                helperText={touched.instrument && errors.instrument}
                                            >
                                                <option value="">-- 1</option>
                                                {instruments?.map((item) => (
                                                    <option key={item.id} value={item.id} selected={values.instrument === item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                                {category && <option value="other">Altro</option>}
                                            </TextField>
                                        </Grid>
                                    )}

                                    <Grid item xs={12} md={6}>
                                        <DateTimePicker
                                            style={{ width: '100%' }}
                                            label="Data inizio"
                                            disabled={!!event}
                                            value={event ? event.date_start : values.date_start}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            onChange={(date) => setFieldValue('date_start', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <DateTimePicker
                                            style={{ width: '100%' }}
                                            label="Data fine"
                                            disabled={!!event}
                                            value={event ? event.date_end : values.date_end}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            onChange={(date) => setFieldValue('date_end', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            label="Orario ritiro"
                                            value={event ? event.date_pickup : values.date_pickup}
                                            disabled={!!event}
                                            inputFormat="HH:mm"
                                            onChange={(date) => setFieldValue('date_pickup', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            disabled={!!event}
                                            value={event ? event.note : formik.values.note}
                                            rows={3}
                                            label="Note"
                                            {...getFieldProps('note')}
                                            error={Boolean(touched.note && errors.note)}
                                            helperText={touched.note && errors.note}
                                        />
                                    </Grid>

                                    {user.role === ROLES.INSEGNANTE &&
                                        event?.confirmed_by_teacher === false &&
                                        event?.profile?.parent === profile && (
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            fullWidth
                                                            multiline
                                                            value={formik.values.confirmed_by_teacher}
                                                            {...getFieldProps('confirmed_by_teacher')}
                                                            error={Boolean(touched.confirmed_by_teacher && errors.confirmed_by_teacher)}
                                                            helperText={touched.confirmed_by_teacher && errors.confirmed_by_teacher}
                                                        />
                                                    }
                                                    label="Prenotazione confermata"
                                                />
                                            </Grid>
                                        )}

                                    {event && returnInst.length ? (
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                <h3 style={{ marginTop: 0 }}>Restituzione</h3>

                                                <p>
                                                    <strong>Data e ora</strong>: {parseDate(returnInst[0].date_return, 'dd/MM/yyyy hh:mm')}
                                                    <br />
                                                    {event.profile.type === 'student' && (
                                                        <>
                                                            <strong>Note studente</strong>: {returnInst[0].student_note}
                                                            <br />
                                                        </>
                                                    )}
                                                    <strong>Note insegnante</strong> : {returnInst[0].teacher_note}
                                                </p>
                                            </Alert>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </DialogContent>

                            <DialogActions sx={{ p: 3 }}>
                                {(user.role === ROLES.STUDENTE || user.role === ROLES.INSEGNANTE) &&
                                    event?.profile?.id === profile &&
                                    !(event.confirmed_by_teacher && event.confirmed_by_admin) && (
                                        <Grid container justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                {!isCreating && (
                                                    <Tooltip title="Delete Event">
                                                        <IconButton onClick={() => deleteEvent(event?.id)} size="large">
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}

                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button type="button" variant="outlined" onClick={onCancel}>
                                            Annulla
                                        </Button>
                                        {user.role === ROLES.STUDENTE && !event ? (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={() => setCheckError(true)}
                                                disabled={isSubmitting}
                                            >
                                                Aggiungi
                                            </Button>
                                        ) : null}
                                        {user.role === ROLES.INSEGNANTE && !event ? (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={() => setCheckError(true)}
                                                disabled={isSubmitting}
                                            >
                                                Aggiungi
                                            </Button>
                                        ) : null}

                                        {event &&
                                        user.role !== ROLES.STUDENTE &&
                                        user.role === ROLES.INSEGNANTE &&
                                        !event.confirmed_by_teacher &&
                                        event.profile.id !== profile ? (
                                            <Button type="submit" variant="contained" disabled={isSubmitting}>
                                                Conferma
                                            </Button>
                                        ) : null}
                                        {event &&
                                        event.confirmed_by_admin &&
                                        ((event.profile.id === profile && returnInst.length <= 0) ||
                                            (event.profile.id !== profile &&
                                                returnInst.length > 0 &&
                                                returnInst[0].status !== 'completed')) &&
                                        (event.profile.id === profile || event?.profile.parent === profile) ? (
                                            <Button variant="contained" onClick={onReturnButtonClick}>
                                                {event.profile.id === profile ? 'Restituisci strumento' : 'Conferma restituzione'}
                                            </Button>
                                        ) : null}
                                    </Stack>
                                </Grid>
                            </DialogActions>
                        </form>
                    )}

                    <ReturnForm
                        getEvents={getEvents}
                        open={returnModal}
                        onClose={onReturnButtonClick}
                        onCancel={onCancel}
                        event={event}
                        options={options}
                        instrumentsReturn={returnInst}
                    />
                </LocalizationProvider>
            )}
            {user.role === ROLES.ADMIN && (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                    {!returnModal && (
                        <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                            <DialogTitle>
                                Prenotazione Strumento
                                {event && (
                                    <Chip
                                        size="large"
                                        label={chipSettings.text}
                                        // chipcolor={item.status === 'active' ? 'success' : 'warning'}
                                        sx={{
                                            position: 'absolute',
                                            backgroundColor: chipSettings.BGcolor,
                                            color: chipSettings.color,
                                            left: chipSettings.left
                                        }}
                                    />
                                )}
                            </DialogTitle>
                            <Divider />
                            <DialogContent sx={{ p: 3 }}>
                                <Grid container spacing={gridSpacing}>
                                    {event && (user.role === ROLES.ADMIN || user.role === ROLES.INSEGNANTE) && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Creato da"
                                                disabled
                                                value={`${event?.user_created?.first_name} ${event?.user_created?.last_name}`}
                                            />
                                        </Grid>
                                    )}
                                    {!event && (user.role === ROLES.ADMIN || user.role === ROLES.INSEGNANTE) && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                value={formik.values.profile}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                label="Utente associato"
                                                {...getFieldProps('profile')}
                                                error={Boolean(touched.profile && errors.profile)}
                                                helperText={touched.profile && errors.profile}
                                            >
                                                <option>--</option>

                                                {users?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {`${item.first_name} ${item.last_name}`}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )}
                                    {event && (user.role === ROLES.ADMIN || user.role === ROLES.INSEGNANTE) && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Utente associato"
                                                disabled
                                                value={`${event?.profile?.first_name} ${event?.profile?.last_name}`}
                                            />
                                        </Grid>
                                    )}
                                    {event ? (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Categoria strumento"
                                                onChange={(e) => {
                                                    setCategory(e.target.value);
                                                    setFieldValue('instrument', null);
                                                }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                            >
                                                <option value={event?.instrument?.category?.id}>{event?.instrument?.category?.name}</option>

                                                {categories.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Categoria strumento"
                                                error={!category && checkError}
                                                helperText={!category && checkError && 'Inserisci la categoria'}
                                                onChange={(e) => {
                                                    setCategory(e.target.value);
                                                    setFieldValue('instrument', '');
                                                }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                            >
                                                <option>--</option>

                                                {categories.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )}
                                    {event ? (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                value={formik.values.instrument}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                label="Strumento"
                                                {...getFieldProps('instrument')}
                                                error={Boolean(touched.instrument && errors.instrument)}
                                                helperText={touched.instrument && errors.instrument}
                                            >
                                                <option value="">--</option>
                                                {instruments?.map((item) => (
                                                    <option key={item.id} value={item.id} selected={values.instrument === item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                                {category && <option value="other">Altro</option>}
                                            </TextField>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                value={formik.values.instrument}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                label="Strumento"
                                                {...getFieldProps('instrument')}
                                                error={Boolean(touched.instrument && errors.instrument)}
                                                helperText={touched.instrument && errors.instrument}
                                            >
                                                <option value="">--3</option>
                                                {instruments?.map((item) => (
                                                    <option key={item.id} value={item.id} selected={values.instrument === item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                                {category && <option value="other">Altro</option>}
                                            </TextField>
                                        </Grid>
                                    )}

                                    {formik.values.instrument === 'other' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={formik.values.other}
                                                {...getFieldProps('other')}
                                                error={Boolean(touched.other && errors.other)}
                                                helperText={touched.other && errors.other}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <DateTimePicker
                                            style={{ width: '100%' }}
                                            label="Data inizio"
                                            value={values.date_start}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            onChange={(date) => {
                                                // date.setHours(9, 0, 0, 0);
                                                setFieldValue('date_start', date);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DateTimePicker
                                            style={{ width: '100%' }}
                                            label="Data fine"
                                            value={values.date_end}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            onChange={(date) => {
                                                setFieldValue('date_end', date);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            label="Ora ritiro strumento"
                                            value={values.date_pickup}
                                            inputFormat="HH:mm"
                                            onChange={(date) => setFieldValue('date_pickup', date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            value={formik.values.note}
                                            rows={3}
                                            label="Note"
                                            {...getFieldProps('note')}
                                            error={Boolean(touched.note && errors.note)}
                                            helperText={touched.note && errors.note}
                                        />
                                    </Grid>

                                    {event && returnInst.length ? (
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                <h3 style={{ marginTop: 0 }}>Restituzione</h3>

                                                <p>
                                                    <strong>Data e ora</strong>: {parseDate(returnInst[0].date_return, 'dd/MM/yyyy hh:mm')}
                                                    <br />
                                                    {event.profile.type === 'student' && (
                                                        <>
                                                            <strong>Note studente</strong>: {returnInst[0].student_note}
                                                            <br />
                                                        </>
                                                    )}
                                                    <strong>Note insegnante</strong>: {returnInst[0].teacher_note}
                                                </p>

                                                {user.role === ROLES.ADMIN && event.status !== 'completed' && (
                                                    <Button onClick={() => closeReservation(event)} variant="contained">
                                                        Chiudi prenotazione
                                                    </Button>
                                                )}
                                            </Alert>
                                        </Grid>
                                    ) : null}

                                    {user.role === ROLES.ADMIN && event?.confirmed_by_admin === false && event?.confirmed_by_teacher && (
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        fullWidth
                                                        multiline
                                                        value={formik.values?.confirmed_by_admin}
                                                        {...getFieldProps('confirmed_by_admin')}
                                                        error={Boolean(touched.confirmed_by_admin && errors.confirmed_by_admin)}
                                                        helperText={touched.confirmed_by_admin && errors.confirmed_by_admin}
                                                    />
                                                }
                                                label="Prenotazione confermata"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>

                            <DialogActions sx={{ p: 3 }}>
                                {user.role === ROLES.ADMIN && (
                                    <Grid container justifyContent="flex-start" alignItems="center">
                                        <Grid item>
                                            {!isCreating && (
                                                <Tooltip title="Delete Event">
                                                    <IconButton onClick={() => deleteEvent(event?.id)} size="large">
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}

                                {(user.role === ROLES.STUDENTE || user.role === ROLES.INSEGNANTE) &&
                                    event?.profile?.id === profile &&
                                    !(event.confirmed_by_teacher && event.confirmed_by_admin) && (
                                        <Grid container justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                {!isCreating && (
                                                    <Tooltip title="Delete Event">
                                                        <IconButton onClick={() => deleteEvent(event?.id)} size="large">
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}

                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button type="button" variant="outlined" onClick={onCancel}>
                                            Annulla
                                        </Button>
                                        {!event ? (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={() => setCheckError(true)}
                                                disabled={isSubmitting}
                                            >
                                                Aggiungi
                                            </Button>
                                        ) : null}
                                        {event &&
                                            (event.confirmed_by_teacher && !event.confirmed_by_admin ? (
                                                <Button type="submit" variant="contained" disabled={isSubmitting}>
                                                    Conferma
                                                </Button>
                                            ) : (
                                                <Button type="submit" variant="contained" disabled={isSubmitting}>
                                                    Aggiorna
                                                </Button>
                                            ))}
                                    </Stack>
                                </Grid>
                            </DialogActions>
                        </form>
                    )}

                    <ReturnForm
                        getEvents={getEvents}
                        open={returnModal}
                        onClose={onReturnButtonClick}
                        onCancel={onCancel}
                        event={event}
                        instrumentsReturn={returnInst}
                    />
                </LocalizationProvider>
            )}
        </FormikProvider>
    );
};

// AddEventFrom.propTypes = {
//     event: PropTypes.object,
//     range: PropTypes.object,
//     handleDelete: PropTypes.func,
//     handleCreate: PropTypes.func,
//     handleUpdate: PropTypes.func,
//     onCancel: PropTypes.func
// };

export default AddEventFrom;
