/**
 * axios setup to use mock service
 */

import axios from 'axios';

const dateTransformer = (data) => {
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toLocaleString('sv').replace(' ', 'T');
    }
    if (Array.isArray(data)) {
        return data.map((val) => dateTransformer(val));
    }
    if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
    }
    return data;
};

const axiosServices = axios.create({
    transformRequest: [dateTransformer].concat(axios.defaults.transformRequest)
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
