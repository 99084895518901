// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconSchool, IconMusic, IconBookmarks, IconBuildingSkyscraper, IconUsers, IconBeach } from '@tabler/icons';
import * as ROLES from '../roles';
// constant
const icons = {
    IconSchool,
    IconMusic,
    IconBookmarks,
    IconBuildingSkyscraper,
    IconUsers,
    IconBeach
};

// ==============================|| CONTENTS MENU ITEMS ||============================== //

const management = {
    id: 'management',
    title: 'Gestione',
    type: 'group',
    roles: [ROLES.ADMIN],
    children: [
        {
            id: 'classrooms',
            title: 'Aule',
            type: 'item',
            url: '/classrooms',
            icon: icons.IconSchool,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'instruments',
            title: 'Strumenti',
            type: 'item',
            url: '/instruments',
            icon: icons.IconMusic,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'instrument-categories',
            title: 'Categorie',
            type: 'item',
            url: '/instrument-categories',
            icon: icons.IconBookmarks,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'locations',
            title: 'Sedi',
            type: 'item',
            url: '/locations',
            icon: icons.IconBuildingSkyscraper,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'users',
            title: 'Utenti',
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        },
        {
            id: 'holidays',
            title: 'Vacanze/chiusure',
            type: 'item',
            url: '/holidays',
            icon: icons.IconBeach,
            breadcrumbs: false,
            roles: [ROLES.ADMIN]
        }
    ]
};

export default management;
