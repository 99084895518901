import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
    return (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <CircularProgress sx={{ margin: 'auto' }} />
        </Box>
    );
}
