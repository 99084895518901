import { useFormik } from 'formik';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import DateRangeIcon from '@mui/icons-material/DateRange';
import * as yup from 'yup';
import axios from 'utils/axios';

import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    InputAdornment,
    // Typography,
    InputLabel
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';

// const validationSchema = yup.object({
//     date_return: yup.string()
// });

const ModalAdmin = ({ open, onClose }) => {
    const [teachNote, setTeachNote] = useState('');
    const [options, setOptions] = useState([]);
    const { user } = useAuth();
    const test = 2;

    const getOptions = async () => {
        const response = await axios.get('/items/options');
        const data = response.data.data;
        setOptions(data);
    };
    useEffect(() => {
        getOptions();
    }, []);
    const initialValues = {
        max_aule_insegnante: options.max_aule_insegnante,
        max_aule_studente: options.max_aule_studente,
        max_ore_aula_insegnante: options.max_ore_aula_insegnante,
        max_ore_aula_studente: options.max_ore_aula_studente,
        max_strumenti_insegnante: options.max_strumenti_insegnante,
        max_strumenti_studente: options.max_strumenti_studente,
        min_preavviso_aula_insegnante: options.min_preavviso_aula_insegnante,
        min_preavviso_aula_studente: options.min_preavviso_aula_studente,
        min_preavviso_strumento_insegnante: options.min_preavviso_strumento_insegnante,
        min_preavviso_strumento_studente: options.min_preavviso_strumento_studente,
        min_ora_ritiro_strumento: options.min_ora_ritiro_strumento,
        max_ora_ritiro_strumento: options.max_ora_ritiro_strumento
    };
    const onSubmitClick = async (data) => {
        await axios.patch('/items/options', data);
        getOptions();
        onClose();
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        // validationSchema,
        onSubmit: async (values, { resetForm }) => {
            onSubmitClick(values);
            resetForm();
        }
    });

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} aria-labelledby="form-dialog-title">
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Pannello di controllo</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Limite aule insegnante"
                                    name="max_aule_insegnante"
                                    value={formik.values.max_aule_insegnante}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_aule_insegnante && Boolean(formik.errors.max_aule_insegnante)}
                                    helperText={formik.touched.max_aule_insegnante && formik.errors.max_aule_insegnante}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Limite aule studente"
                                    name="max_aule_studente"
                                    value={formik.values.max_aule_studente}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_aule_studente && Boolean(formik.errors.max_aule_studente)}
                                    helperText={formik.touched.max_aule_studente && formik.errors.max_aule_studente}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Limite ore aula insegnante"
                                    name="max_ore_aula_insegnante"
                                    value={formik.values.max_ore_aula_insegnante}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_ore_aula_insegnante && Boolean(formik.errors.max_ore_aula_insegnante)}
                                    helperText={formik.touched.max_ore_aula_insegnante && formik.errors.max_ore_aula_insegnante}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Limite ore aula studente"
                                    name="max_ore_aula_studente"
                                    value={formik.values.max_ore_aula_studente}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_ore_aula_studente && Boolean(formik.errors.max_ore_aula_studente)}
                                    helperText={formik.touched.max_ore_aula_studente && formik.errors.max_ore_aula_studente}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Limite strumenti insegnante"
                                    name="max_strumenti_insegnante"
                                    value={formik.values.max_strumenti_insegnante}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_strumenti_insegnante && Boolean(formik.errors.max_strumenti_insegnante)}
                                    helperText={formik.touched.max_strumenti_insegnante && formik.errors.max_strumenti_insegnante}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Limite strumenti studente"
                                    name="max_strumenti_studente"
                                    value={formik.values.max_strumenti_studente}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_strumenti_studente && Boolean(formik.errors.max_strumenti_studente)}
                                    helperText={formik.touched.max_strumenti_studente && formik.errors.max_strumenti_studente}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Tempo preavviso aula insegnante"
                                    name="min_preavviso_aula_insegnante"
                                    value={formik.values.min_preavviso_aula_insegnante}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.min_preavviso_aula_insegnante && Boolean(formik.errors.min_preavviso_aula_insegnante)
                                    }
                                    helperText={formik.touched.min_preavviso_aula_insegnante && formik.errors.min_preavviso_aula_insegnante}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Tempo preavviso aula studente"
                                    name="min_preavviso_aula_studente"
                                    value={formik.values.min_preavviso_aula_studente}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.min_preavviso_aula_studente && Boolean(formik.errors.min_preavviso_aula_studente)}
                                    helperText={formik.touched.min_preavviso_aula_studente && formik.errors.min_preavviso_aula_studente}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Tempo preavviso strumento insegnante"
                                    name="min_preavviso_strumento_insegnante"
                                    value={formik.values.min_preavviso_strumento_insegnante}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.min_preavviso_strumento_insegnante &&
                                        Boolean(formik.errors.min_preavviso_strumento_insegnante)
                                    }
                                    helperText={
                                        formik.touched.min_preavviso_strumento_insegnante &&
                                        formik.errors.min_preavviso_strumento_insegnante
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Tempo preavviso strumento studente"
                                    name="min_preavviso_strumento_studente"
                                    value={formik.values.min_preavviso_strumento_studente}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.min_preavviso_strumento_studente &&
                                        Boolean(formik.errors.min_preavviso_strumento_studente)
                                    }
                                    helperText={
                                        formik.touched.min_preavviso_strumento_studente && formik.errors.min_preavviso_strumento_studente
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Orario minimo per ritiro strumento"
                                    name="min_preavviso_strumento_insegnante"
                                    value={formik.values.min_ora_ritiro_strumento}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.min_ora_ritiro_strumento && Boolean(formik.errors.min_ora_ritiro_strumento)}
                                    helperText={formik.touched.min_ora_ritiro_strumento && formik.errors.min_ora_ritiro_strumento}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="number"
                                    label="Orario massimo per ritiro strumento"
                                    name="min_preavviso_strumento_studente"
                                    value={formik.values.max_ora_ritiro_strumento}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.max_ora_ritiro_strumento && Boolean(formik.errors.max_ora_ritiro_strumento)}
                                    helperText={formik.touched.max_ora_ritiro_strumento && formik.errors.max_ora_ritiro_strumento}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
                        <Button onClick={onClose} color="secondary">
                            Annulla
                        </Button>
                        <Button variant="contained" type="submit">
                            Salva
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

export default ModalAdmin;
