import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    // FormControlLabel,
    // Typography,
    InputLabel
} from '@mui/material';
import { useEffect } from 'react';
import axios from 'utils/axios';

const ModalStatusChange = ({
    open,
    onModalClick,
    getClassrooms,
    getInstrumentCat,
    onStatusClick,
    getLocations,
    getIstruments,
    id,
    aula = false,
    strumento = false,
    categoria = false,
    sede = false
}) => {
    const initialValues = {
        status: ''
    };

    let request;
    let message;
    if (aula) {
        request = `/items/classrooms/${id}`;
        message = "Inserisci lo status dell'aula";
    }
    if (strumento) {
        request = `/items/instruments/${id}`;
        message = 'Inserisci lo status dello strumento';
    }
    if (categoria) {
        request = `/items/instrument_categories/${id}`;
        message = 'Inserisci lo status della categoria';
    }
    if (sede) {
        request = `/items/locations/${id}`;
        message = 'Inserisci lo status della sede';
    }
    const validationSchema = yup.object({
        status: yup.string().required(message)
    });

    const onSubmitClick = async (values) => {
        await axios.patch(request, values);
        onStatusClick();
        if (aula) {
            getClassrooms();
        }
        if (categoria) {
            getInstrumentCat();
        }
        if (sede) {
            getLocations();
        }
        if (strumento) {
            getIstruments();
        }
    };
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            onSubmitClick(values);
            resetForm();
        }
    });
    const onClose = () => {
        onStatusClick();
        formik.resetForm();
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} aria-labelledby="form-dialog-title">
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Cambia status</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack>
                                    <TextField
                                        label="status"
                                        name="status"
                                        select
                                        SelectProps={{
                                            native: true
                                        }}
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.status && Boolean(formik.errors.status)}
                                        helperText={formik.touched.status && formik.errors.status}
                                        fullWidth
                                    >
                                        <option> -- </option>
                                        <option value="publish">Pubblico</option>
                                        <option value="private">Privato</option>
                                    </TextField>
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
                        <Button onClick={onStatusClick} color="secondary">
                            Annulla
                        </Button>
                        <Button variant="contained" type="submit">
                            Salva
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};
export default ModalStatusChange;
