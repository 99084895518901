// assets
import { IconSchool, IconMusic } from '@tabler/icons';

import * as ROLES from '../roles';
// constant
const icons = {
    IconSchool,
    IconMusic
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'reservations',
    title: 'Prenotazioni',
    type: 'group',
    roles: [ROLES.ADMIN, ROLES.ESTERNO, ROLES.INSEGNANTE, ROLES.STUDENTE],
    children: [
        {
            id: 'classroom-reservations',
            title: 'Aule',
            type: 'item',
            url: '/classroom-reservations',
            icon: icons.IconSchool,
            breadcrumbs: false,
            roles: [ROLES.ADMIN, ROLES.ESTERNO, ROLES.INSEGNANTE, ROLES.STUDENTE]
        },
        {
            id: 'instrument-reservations',
            title: 'Strumenti',
            type: 'item',
            url: '/instrument-reservations',
            icon: icons.IconMusic,
            breadcrumbs: false,
            roles: [ROLES.ADMIN, ROLES.ESTERNO, ROLES.INSEGNANTE, ROLES.STUDENTE]
        }
    ]
};

export default dashboard;
