import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Holidays from '../views/holidays';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// classrooms routing
const Classrooms = Loadable(lazy(() => import('views/classrooms')));

// instruments routing
const Instruments = Loadable(lazy(() => import('views/instruments')));

// instrument categories routing
const InstrumentCategories = Loadable(lazy(() => import('views/instrument-categories')));

// locations categories routing
const Locations = Loadable(lazy(() => import('views/locations')));

// users routing
const Users = Loadable(lazy(() => import('views/users')));

// instrument reservations routing
const InstrumentReservations = Loadable(lazy(() => import('views/instrument-reservations')));

// classroom reservations routing
const ClassroomReservations = Loadable(lazy(() => import('views/classroom-reservations')));
const Notifications = Loadable(lazy(() => import('views/notifications')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/classrooms',
            element: <Classrooms />
        },
        {
            path: '/instruments',
            element: <Instruments />
        },
        {
            path: '/instrument-categories',
            element: <InstrumentCategories />
        },
        {
            path: '/locations',
            element: <Locations />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/holidays',
            element: <Holidays />
        },
        {
            path: '/classroom-reservations',
            element: <ClassroomReservations />
        },
        {
            path: '/instrument-reservations',
            element: <InstrumentReservations />
        },
        {
            path: '/notifications',
            element: <Notifications />
        }
    ]
};

export default MainRoutes;
