import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    // FormControlLabel,
    // Typography,
    InputLabel
} from '@mui/material';
import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { openSnackbar } from '../../store/slices/snackbar';
import { useDispatch } from 'store';
import itLocale from 'date-fns/locale/it';

const validationSchema = yup.object({
    description: yup.string().required(`Inserisci la descrizione`),
    start_date: yup.string().required(`Inserisci la data inizio`),
    end_date: yup.string().required(`Inserisci la data inizio`)
});
const ModalRecord = ({ open, onModalClick, setNewRecord, onUpdate, record }) => {
    const dispatch = useDispatch();

    const initialValues = {
        name: '',
        start_date: '',
        end_date: ''
    };
    let nome = 'Nuova chiusura';
    if (record) {
        nome = 'Modifica chiusura';
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if ((new Date(values.end_date) - new Date(values.start_date)) / 1000 / 60 / 60 / 24 < 0) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'La data fine deve essere successiva a quella di inizio',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }

            if (values.id) {
                await onUpdate(values);
            } else {
                await setNewRecord(values);
            }
            resetForm();
        }
    });
    useEffect(() => {
        if (record) {
            formik.setValues({
                description: record.description,
                start_date: record.start_date,
                end_date: record.end_date,
                id: record.id
            });
        }
    }, [record]);
    const onClose = () => {
        onModalClick();
        formik.resetForm();
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} aria-labelledby="form-dialog-title">
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{nome}</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack>
                                    <TextField
                                        label="Descrizione"
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.description && Boolean(formik.errors.description)}
                                        helperText={formik.touched.description && formik.errors.description}
                                        fullWidth
                                    />
                                </Stack>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="Data inizio"
                                        value={formik.values.start_date}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(date) => formik.setFieldValue('start_date', date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="Data fine"
                                        value={formik.values.end_date}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(date) => formik.setFieldValue('end_date', date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
                        <Button onClick={onModalClick} color="secondary">
                            Annulla
                        </Button>
                        <Button variant="contained" type="submit">
                            Salva
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};
export default ModalRecord;
