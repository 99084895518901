import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const LandingPage = Loadable(lazy(() => import('views/pages/landing/index')));

// ==============================|| PUBLIC ROUTING ||============================== //

const PublicRoutes = {
    path: '/landing',
    element: <LandingPage />
};

export default PublicRoutes;
