import dashboard from './dashboard';
import management from './management';
import reservations from './reservations';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, management, reservations]
};

export default menuItems;
