import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'utils/axios';
import * as ROLES from '../../roles';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const ReturnForm = ({ open, onClose, onCancel, event, instrumentsReturn, getEvents, options }) => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    let initialValues = {
        date_return: '',
        reservation: event?.id,
        student_note: '',
        teacher_note: '',
        status: ''
    };
    let validationSchema;
    if (user.role === ROLES.STUDENTE) {
        validationSchema = yup.object({
            student_note: yup.string().required('Inserisci la nota'),
            date_return: yup.string().required('Inserisci la data e ora di restituzione strumento')
        });
    }

    if (user.role === ROLES.INSEGNANTE) {
        validationSchema = yup.object({
            teacher_note: yup.string().required('Inserisci la nota')
        });
    }
    if (user.role === ROLES.INSEGNANTE && instrumentsReturn.length === 0) {
        validationSchema = yup.object({
            teacher_note: yup.string().required('Inserisci la nota'),
            date_return: yup.string().required('Inserisci la data e ora di restituzione strumento')
        });
    }

    if (user.role === ROLES.INSEGNANTE && instrumentsReturn.length === 0) {
        initialValues = {
            date_return: '',
            reservation: event?.id,
            teacher_note: '',
            status: 'completed'
        };
    }

    if (user.role === ROLES.INSEGNANTE && instrumentsReturn?.[0]?.date_return !== null) {
        initialValues = {
            reservation: event?.id,
            teacher_note: '',
            status: 'completed',
            date_return: ''
        };
    }

    const onCancelClick = () => {
        onClose();
        onCancel();
    };

    const onStudentConfirm = async (data) => {
        await axios.post('/items/instrument_returns', data);
        getEvents();
        onCancelClick();
    };
    const onTeacherComplete = async (id, data) => {
        await axios.patch(`/items/instrument_returns/${id}`, data);
        getEvents();
        onCancelClick();
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (
                new Date(values?.date_return)?.getHours() < options.min_ora_ritiro_strumento ||
                new Date(values?.date_return)?.getHours() > options.max_ora_ritiro_strumento ||
                new Date(values?.date_return).getDay() === 0 ||
                new Date(values?.date_return).getDay() === 6
            ) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Lo strumento può essere restituito solo dal Lunedì al Venerdì dalle ${options.min_ora_ritiro_strumento} alle ${options.max_ora_ritiro_strumento}`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }
            if (+new Date(values?.date_return) <= +new Date(event?.date_pickup)) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'La data di restituzione deve essere successiva al giorno del ritiro dello strumento',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
                return;
            }
            if (user.role === ROLES.STUDENTE) {
                onStudentConfirm(values);
            }
            if (user.role === ROLES.INSEGNANTE && instrumentsReturn.length === 0) {
                onStudentConfirm(values);
            }
            if (user.role === ROLES.INSEGNANTE && instrumentsReturn.length > 0) {
                onTeacherComplete(
                    instrumentsReturn.map((item) => item.id),
                    { reservation: values.reservation, teacher_note: values.teacher_note, status: values.status }
                );
            }
            resetForm();
            // console.log('VALLL', new Date(values.date_return).getHours());
        }
    });

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} aria-labelledby="form-dialog-title">
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle id="form-dialog-title">Restituzione strumento</DialogTitle>
                    <DialogContent dividers>
                        {user.role === ROLES.STUDENTE && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DateTimePicker
                                        label="Data e ora restituzione strumento"
                                        value={formik.values?.date_return}
                                        onChange={(date) => formik.setFieldValue('date_return', date)}
                                        inputFormat="dd/MM/yyyy hh:mm a"
                                        renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Descrizione stato dello strumento"
                                        multiline
                                        rows={4}
                                        name="student_note"
                                        value={formik.values.student_note}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.student_note && Boolean(formik.errors.student_note)}
                                        helperText={formik.touched.student_note && formik.errors.student_note}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {user.role === ROLES.INSEGNANTE && (
                            <Grid container spacing={3}>
                                {instrumentsReturn.length > 0 ? (
                                    <Grid item xs={12}>
                                        <TextField fullWidth disabled value={`${event.profile.first_name} ${event.profile.last_name}`} />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <DateTimePicker
                                            label="Data e ora ristituzione strumento"
                                            value={formik.values?.date_return}
                                            onChange={(date) => formik.setFieldValue('date_return', date)}
                                            inputFormat="dd/MM/yyyy hh:mm a"
                                            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                                        />
                                    </Grid>
                                )}
                                {instrumentsReturn[0]?.student_note && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            label="Note dello studente"
                                            multiline
                                            rows={4}
                                            value={instrumentsReturn.map((item) => item.student_note)}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <TextField
                                        label="Nota"
                                        multiline
                                        rows={4}
                                        name="teacher_note"
                                        value={formik.values.teacher_note}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.teacher_note && Boolean(formik.errors.teacher_note)}
                                        helperText={formik.touched.teacher_note && formik.errors.teacher_note}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
                        <Button onClick={onCancelClick} color="secondary">
                            Cancella
                        </Button>
                        <Button variant="contained" type="submit" disabled={!formik.isValid}>
                            {user.role === ROLES.STUDENTE ? 'Invia richiesta' : 'Completa restituzione'}
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

export default ReturnForm;
