// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconSchool, IconMusic } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import axios from 'utils/axios';
import { Link } from 'react-router-dom';
import * as ROLES from '../../../../roles';
import useAuth from '../../../../hooks/useAuth';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notifications, reloadNotifications, handleConfirmInstrumentReservation }) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const onNotificationClick = async (id) => {
        const data = {
            read: true
        };
        await axios.patch(`/items/notifications/${id}`, data);
        reloadNotifications();
    };

    const { user } = useAuth();

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((item) => (
                <ListItem style={{ display: 'block', padding: 0 }}>
                    <Link
                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', padding: 16 }}
                        to="/notifications"
                        onClick={() => onNotificationClick(item.id)}
                    >
                        <ListItemAvatar>
                            {item.reservation === 'instrument' ? (
                                <Avatar
                                    sx={{
                                        color: theme.palette.primary.dark,
                                        backgroundColor:
                                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                        borderColor: theme.palette.primary.main
                                    }}
                                >
                                    <IconMusic stroke={1.5} size="21px" />
                                </Avatar>
                            ) : (
                                <Avatar
                                    sx={{
                                        color: '#fc9323',
                                        backgroundColor: '#ffe7aa',

                                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                        borderColor: theme.palette.primary.main
                                    }}
                                >
                                    <IconSchool stroke={1.5} size="21px" />
                                </Avatar>
                            )}
                        </ListItemAvatar>
                        <Typography variant="subtitle2">{item.message}</Typography>
                        {!item.read || item.instrument_reservation ? (
                            <div style={{ display: 'flex' }}>
                                {!item.read && <Chip label="Non letto" sx={chipErrorSX} />}
                                {item.instrument_reservation &&
                                    ((user.role === ROLES.ADMIN &&
                                        !item.instrument_reservation.confirmed_by_admin &&
                                        item.instrument_reservation.confirmed_by_teacher) ||
                                        (user.role === ROLES.INSEGNANTE && !item.instrument_reservation.confirmed_by_teacher)) && (
                                        <div style={{ paddingRight: 20 }}>
                                            <Button
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleConfirmInstrumentReservation(item.instrument_reservation);
                                                }}
                                            >
                                                Conferma
                                            </Button>
                                        </div>
                                    )}
                            </div>
                        ) : null}
                    </Link>
                    <Divider />
                </ListItem>
            ))}
        </List>
    );
};

export default NotificationList;
