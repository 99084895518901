// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import * as ROLES from '../roles';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Generale',
    type: 'group',
    roles: [ROLES.ADMIN, ROLES.INSEGNANTE, ROLES.STUDENTE],
    children: [
        {
            id: 'default',
            title: 'Panoramica',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            roles: [ROLES.ADMIN, ROLES.INSEGNANTE, ROLES.STUDENTE]
        }
    ]
};

export default dashboard;
