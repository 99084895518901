// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    events: []
};

const slice = createSlice({
    name: 'instrumentsCalendar',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.events = action.payload;
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            state.events = action.payload;
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            state.events = action.payload;
        },

        // REMOVE EVENT
        removeEventSuccess(state, action) {
            state.events = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// TODO: remove
let events = [
    // {
    //     id: 1,
    //     title: 'titolo evento',
    //     start: '2022-03-16 09:00:00',
    //     end: '2022-03-16 12:00:00',
    //     allDay: true
    // },
    // {
    //     id: 2,
    //     title: 'titolo evento 2',
    //     start: '2022-03-17 09:00:00',
    //     end: '2022-03-17 12:00:00',
    //     allDay: false
    // }
];

export function getEvents() {
    return async () => {
        try {
            // TODO: ripristinare chiamate API
            // const response = await axios.get('/api/calendar/events');
            // const events = response.data.events;

            dispatch(slice.actions.getEventsSuccess(events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEvent(event) {
    return async () => {
        try {
            // TODO: ripristinare chiamate API
            // const response = await axios.post('/api/calendar/events/new', event);
            dispatch(slice.actions.addEventSuccess(event));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateEvent({ eventId, update }) {
    return async () => {
        try {
            // TODO: ripristinare chiamate API
            // const response = await axios.post('/api/calendar/events/update', event);
            // const events = response.data.events;
            events = [...events.map((eventItem) => (eventItem.id === eventId ? { ...eventItem, ...update } : eventItem))]; // TODO: remove
            dispatch(slice.actions.updateEventSuccess(events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeEvent(eventId) {
    return async () => {
        try {
            // TODO: ripristinare chiamate API
            // const response = await axios.post('/api/calendar/events/remove', { eventId });
            // const events = response.data;
            events = [...events.filter((eventItem) => eventItem.id !== eventId)]; // TODO: remove
            dispatch(slice.actions.removeEventSuccess(events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
